import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@src/@fuse';
import { FuseConfigModule } from '@src/@fuse/services/config';
import { FuseMockApiModule } from '@src/@fuse/lib/mock-api';
import { CoreModule } from '@app/core/core.module';
import { appConfig } from '@app/core/config/app.config';
// import { mockApiServices } from '@app/mock-api';
import { LayoutModule } from '@app/layout/layout.module';
import { AppComponent } from '@app/app.component';
import { appRoutes } from '@app/app.routing';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { PipesModule } from './pipes/pipes.module';
import * as Sentry from "@sentry/angular-ivy";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from "@angular/material/checkbox";


const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy       : PreloadAllModules,
    initialNavigation: 'enabled',
    paramsInheritanceStrategy: 'always'
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        // FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        MatProgressBarModule,
        MatSnackBarModule,
        MatCheckboxModule,

    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-NZ'},
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: true,
            }),
          }, {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
    ]
})
export class AppModule
{
}
