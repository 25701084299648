import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { of } from 'rxjs';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GwcApiConnService {

  	constructor(
		  private _httpClient: HttpClient
	  ) { }

	  public request(method: string, endpoint: string | string[], queryParams?: { [key: string]: string} | null, body?: any, headers?: HttpHeaders): Observable<any> {

		//create the query params
		const opts: { params?: HttpParams } = {};
		if(queryParams) {
			opts["params"] = new HttpParams({fromObject: queryParams});
		}

		if(Array.isArray(endpoint)) {
			endpoint = endpoint.join('/')
		}
		
		const httpHeaders = headers ? headers : new HttpHeaders().set('Content-Type', '@application/json')

		let req;

		switch(method) {
			case 'GET' :
				req = this._httpClient.get(`${environment.apiBaseUrl}/${endpoint}`, opts);
				break;
			case 'POST' :
				req = this._httpClient.post(`${environment.apiBaseUrl}/${endpoint}`, headers ? body : JSON.stringify(body), { headers: httpHeaders })
				break;
			case 'PUT' : 
				req = this._httpClient.put(`${environment.apiBaseUrl}/${endpoint}`, JSON.stringify(body), { headers: httpHeaders })
				break;
			case 'DELETE' : 
				req = this._httpClient.delete(`${environment.apiBaseUrl}/${endpoint}`);
				break;
			default : 
				req = of({})
				break;
		}

		return req;

	}




}
