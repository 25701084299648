import { Injectable } from '@angular/core';
import { GwcApiConnService } from './gwc-api-conn.service';
import { Observable } from 'rxjs';
import { Institution } from '@src/app/types/gwc-api/institution.types';
import { Graduation } from '@src/app/types/gwc-api/graduation.types';
@Injectable({
    providedIn: 'root'
})
export class GwcApiInstitutionsService {


    constructor(
        private _connService: GwcApiConnService
    ) {

    }

    //------------------------------------------------------------------
    // @Basic CRUD Methods
    //------------------------------------------------------------------

    getInstitutions(params?): Observable<Institution[]> {
        return this._connService.request('GET', 'Institution', params) as Observable<Institution[]>
    }

    getInstitution(institution_id: string, options?: { institution_type?: string, bo_casual_hire?: boolean, name?: string }): Observable<Institution> {
        const params: any = {};
    
        if (options?.institution_type) {
          params.institution_type = options.institution_type;
        }
        if (options?.bo_casual_hire !== undefined) {
          params.bo_casual_hire = options.bo_casual_hire;
        }
        if (options?.name) {
          params.name = options.name;
        }
    
        return this._connService.request('GET', ['Institution', institution_id], { params }) as Observable<Institution>;
      }

    createInstitution(data): Observable<any> {
        return this._connService.request('POST', ['Institution'], null, data) as Observable<any>
    }

    updateInstitution(institution_id: string, data): Observable<any> {
        return this._connService.request('PUT', ['Institution', institution_id], null, data) as Observable<any>
    }


    //------------------------------------------------------------------
    // @Advances Methods
    //------------------------------------------------------------------

    getGraduations(institution_id: string, queryParams?: { [key: string]: string } ): Observable<Graduation[]> {
        return this._connService.request('GET', ['Institution', institution_id, 'graduations'], queryParams) as Observable<Graduation[]>
    }

    getGraduationsCeremonies(institution_id: string): Observable<Graduation[]> {
        return this._connService.request('GET', ['Institution', institution_id, 'graduations', 'ceremonies']) as Observable<Graduation[]>
    }

    getQualifications(institution_id: string, params?): Observable<any> {
        return this._connService.request('GET', ['Institution', institution_id, 'qualifications'], params) as Observable<any>
    }

    getGraduationDashboard(graduation_id: string, options: {mainCeremonyIds: string[], additionalCeremonyIds: string[]}): Observable<any> {
        const queryParams = {
            main_ceremony_ids: options.mainCeremonyIds.join(','),
            additional_ceremony_ids: options.additionalCeremonyIds.join(',')
        };
        console.log('getGraduationDashboard', graduation_id, queryParams)
        return this._connService.request('GET', ['v2', 'graduations', graduation_id, 'dashboard'], queryParams) as Observable<any>
    }

    generatePickUpEmails(graduation_id: string): Observable<any> {
        return this._connService.request('POST', ['Graduation', graduation_id, 'IndividualOrders', 'PickupNotification', 'generate']) as Observable<any>
    }

    getNotificationStats(graduation_id: string): Observable<any> {
        return this._connService.request('GET', ['Graduation', graduation_id, 'IndividualOrders', 'NotificationStats']) as Observable<any>
    }

    exportOrdersToCSV(graduation_id: string): Observable<any> {
        return this._connService.request('GET', ['Graduation', graduation_id, 'RFIDExport', 'csv']) as Observable<any>
    }

    openStudentLink(graduation_id: string): Observable<any> {
        return this._connService.request('POST', ['Graduation', graduation_id, 'StudentLink'], {}, {linkOpen: true}) as Observable<any>
    }

    closeStudentLink(graduation_id: string): Observable<any> {
        return this._connService.request('POST', ['Graduation', graduation_id, 'StudentLink'], {}, {linkOpen: false}) as Observable<any>
    }

}